import cx from 'classnames';
import { ReactSVG } from 'react-svg';

import { Event, trackEvent } from '~/ui/components/analytics';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Image } from '~/v1/components/image/image';
import { Link } from '~/v1/components/link/link';
import { TEST_ID } from '~/v1/constants/testId';

import { type GrantCardType } from './grant.interface';
import styles from './grant.module.scss';

interface GrantCardProps extends GrantCardType {
  className?: string;
}

export const GrantCard: React.FC<GrantCardProps> = ({
  href,
  title,
  eyebrow,
  linkText,
  isExternal,
  className,
  gtm,
  logo,
}) => {
  return (
    <FullBleedCTA
      label={title}
      className={cx(styles.card, className)}
      data-test-id={TEST_ID.CARD.GRANT}
    >
      <div className={styles.content}>
        {logo && (
          <div>
            {logo.src.endsWith('.svg') ? (
              <ReactSVG src={logo.src} className={styles.logo} title={logo.alt} />
            ) : (
              <Image aspectRatio="4/1" className={styles.logo} src={logo.src} alt={logo.alt} />
            )}
          </div>
        )}
        <div className={cx('bodySmallTypography', styles.eyebrow)}>{eyebrow}</div>
        <div className="bodyLargeTypography">{title}</div>
      </div>
      <FullBleedCTATrigger>
        <Link
          href={href}
          onClick={() =>
            trackEvent(Event.CARD_CLICK, {
              cardModule: gtm.cardModule,
              cardType: gtm.cardType,
              cardContentType: gtm.cardContentType,
              cardCTA: linkText,
              cardTitle: title,
            })
          }
          withIcon={isExternal ? IconType.Pdf : true}
        >
          {linkText}
        </Link>
      </FullBleedCTATrigger>
    </FullBleedCTA>
  );
};
